import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { makeStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) {
  return {
    container: _objectSpread(_objectSpread({
      padding: theme.spacing(1.5)
    }, theme.border({
      width: 1,
      color: theme.palette.line[100],
      radius: 6
    })), {}, {
      display: 'flex',
      textDecoration: 'none'
    }),
    iconContainer: {
      padding: theme.spacing(1.5),
      borderRadius: '50%',
      backgroundColor: theme.palette.grey.light,
      marginBottom: 'auto'
    },
    icon: {
      width: 32,
      height: 32
    },
    bookIconContainer: {
      backgroundColor: theme.palette.primary.main
    },
    bookIcon: {
      color: theme.palette.common.white
    },
    info: {
      marginLeft: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      wordWrap: 'break-word'
    },
    fileName: {
      fontWeight: 600,
      lineHeight: '19px'
    },
    fileSize: {
      opacity: 0.6,
      lineHeight: '17px',
      marginTop: theme.spacing(1)
    }
  };
});
export default useStyles;