import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0.5),
      justifyContent: 'center',
      alignItems: 'flex-start',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    disabled: {
      '&:hover': {
        cursor: 'not-allowed',
        opacity: 0.7
      }
    },
    fileContainer: _defineProperty({
      width: '100%',
      borderRadius: 4,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(1)
    }, theme.breakpoints.up('sm'), {
      width: 230
    }),
    imageContainer: _objectSpread(_objectSpread({
      overflow: 'hidden',
      height: 180,
      width: 243
    }, theme.border({
      radius: 4
    })), {}, _defineProperty({
      '& img': {
        width: '100%'
      }
    }, theme.breakpoints.up('sm'), {
      height: 130,
      width: '100%'
    })),
    fileTitle: {
      fontWeight: 400,
      marginTop: theme.spacing(2),
      lineHeight: 'normal'
    },
    errorContainer: _defineProperty({
      height: 180,
      width: 243,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 4,
      backgroundColor: theme.palette.secondary[100]
    }, theme.breakpoints.up('sm'), {
      height: 130
    }),
    pdfIcon: {
      height: 64,
      width: 64
    },
    sponsorLogo: {
      objectFit: 'contain'
    }
  };
});
export default useStyles;