import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { makeStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) {
  return {
    day: _defineProperty({
      marginTop: theme.spacing(3),
      fontWeight: 600
    }, theme.breakpoints.up('sm'), {
      fontSize: 29,
      lineHeight: '44px',
      marginTop: 0
    }),
    accordionExpanded: {
      // The following important is useful to override the Accordion styles.
      // It was not possible to do it easily with index priority.
      minHeight: 'unset!important',
      '& div:nth-child(2)': {
        position: 'absolute',
        top: 0,
        right: 16,
        display: 'flex',
        alignItems: 'flex-end'
      }
    },
    block: _defineProperty({
      margin: theme.spacing(2.5, 0, 2, 0),
      fontWeight: 600
    }, theme.breakpoints.up('sm'), {
      marginLeft: 0,
      fontSize: 20,
      lineHeight: '30px',
      marginTop: theme.spacing(3)
    }),
    lectureContainer: _objectSpread({
      marginTop: theme.spacing(1),
      padding: theme.spacing(1.5, 1.5, 1.5, 2),
      backgroundColor: theme.palette.grey[100],
      display: 'flex'
    }, theme.border({
      radius: 6,
      width: 0
    })),
    smallBox: _defineProperty({
      padding: theme.spacing(1.5, 1.5, 1.5, 2),
      display: 'flex',
      alignItems: 'center'
    }, theme.breakpoints.up('sm'), {
      marginLeft: 0
    }),
    time: {
      marginRight: theme.spacing(3),
      marginBottom: 'auto',
      color: theme.palette.body[700]
    },
    register: {
      fontStyle: 'italic',
      opacity: 0.6
    },
    beginEnd: {
      fontStyle: 'italic',
      opacity: 0.6
    },
    endSchedule: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    organizational: {
      fontStyle: 'italic',
      opacity: 0.6
    },
    lightText: {
      color: theme.palette.body.dark,
      fontSize: 12
    },
    largeBox: _defineProperty({
      padding: theme.spacing(1.5, 2),
      borderRadius: 6,
      marginTop: theme.spacing(1)
    }, theme.breakpoints.up('sm'), {
      marginLeft: 0
    }),
    largeBoxContent: {
      flexDirection: 'column'
    },
    speaker: {
      color: theme.palette.body.dark,
      fontWeight: 400,
      fontSize: 12
    },
    lecture: _objectSpread({
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.grey[100]
    }, theme.border({
      radius: 6,
      width: 0
    })),
    topicBlock: _defineProperty({
      fontWeight: 600,
      margin: theme.spacing(2, 4, 1.5, 2)
    }, theme.breakpoints.up('sm'), {
      marginLeft: theme.spacing(2),
      fontSize: 16,
      lineHeight: '24px'
    }),
    workshopDate: {
      fontSize: 14,
      position: 'absolute',
      top: 0
    },
    workshopTitle: {
      fontSize: 14,
      fontWeight: 700
    },
    workshopTitleContainer: {
      position: 'absolute',
      left: 120,
      top: 0
    },
    workshopSpeakersContainer: {
      marginLeft: 108
    }
  };
}, {
  name: 'ProgramSchedule'
});
export default useStyles;