import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
  var _titleContainer;

  return {
    container: _objectSpread(_objectSpread({
      position: 'relative',
      display: 'grid',
      gap: theme.spacing(2.5),
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3)
    }, theme.border({
      radius: 12,
      color: 'transparent'
    })), {}, _defineProperty({}, theme.breakpoints.up('sm'), {
      gap: theme.spacing(3),
      gridTemplateColumns: 'repeat(2, auto)',
      gridTemplateRows: 'repeat(3, auto)'
    })),
    titleContainer: (_titleContainer = {
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none'
    }, _defineProperty(_titleContainer, theme.breakpoints.up('sm'), {
      gridRow: '1/1',
      gridColumn: '1/1'
    }), _defineProperty(_titleContainer, '&:hover', {
      textDecoration: 'underline',
      textDecorationColor: 'black'
    }), _titleContainer),
    subtitleText: {
      color: '#59748D'
    },
    progressContainer: _defineProperty({}, theme.breakpoints.up('sm'), {
      justifySelf: 'flex-end',
      flexDirection: 'row-reverse',
      gridRow: '1/1',
      gridColumn: '2/2'
    }),
    currentContentContainer: _defineProperty({}, theme.breakpoints.up('sm'), {
      gridRow: '3/3',
      gridColumn: '1/1'
    }),
    actionButtonContainer: _defineProperty({
      justifySelf: 'flex-end'
    }, theme.breakpoints.up('sm'), {
      alignSelf: 'flex-end',
      gridRow: '3/3',
      gridColumn: '2/2'
    }),
    actionButton: {},
    actionButtonNotStarted: {
      background: theme.palette.success['500'],
      color: theme.palette.common.white
    },
    actionButtonTitle: {
      textTransform: 'none'
    },
    actionButtonIcon: {
      width: 16,
      height: 16
    },
    notStarted: {},
    compact: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: theme.spacing(2.5),
      // padding 24px + button 42px + gap 20px
      paddingBottom: 24 + 42 + 20,
      '& $titleContainer, & $progressContainer': {
        width: '100%'
      },
      '& $progressContainer': {
        flexDirection: 'row-reverse',
        justifySelf: 'flex-start',
        justifyContent: 'flex-end'
      },
      '& $currentContent': {
        flexDirection: 'column'
      },
      '& $actionButtonContainer': {
        // I wasn't able to find a way to clip it to the bottom with flex or grid
        position: 'absolute',
        left: theme.spacing(3),
        right: theme.spacing(3),
        bottom: theme.spacing(3),
        '& $actionButton': {
          width: '100%'
        }
      }
    },
    currentContent: {
      paddingLeft: 0
    }
  };
}, {
  name: 'AddonCard',
  // index to override Button styles
  index: 1
});
export default useStyles;