import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
  return {
    container: _defineProperty({
      display: 'flex',
      gap: theme.spacing(1.5),
      whiteSpace: 'nowrap'
    }, theme.breakpoints.up('sm'), {
      gap: theme.spacing(2)
    }),
    progress: {
      position: 'relative',
      flexShrink: 0,
      '&::before': _objectSpread({
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }, theme.border({
        width: 5,
        color: theme.palette.body[200],
        radius: '50%'
      }))
    },
    svg: {
      // make it draw over ":before" element
      position: 'relative',
      zIndex: 1
    }
  };
}, {
  name: 'AddonContentProgress'
});
export default useStyles;