import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { makeStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) {
  return {
    help: {
      opacity: 0.6,
      marginTop: theme.spacing(2)
    },
    attachments: _defineProperty({
      marginTop: theme.spacing(6),
      gap: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      width: 'auto'
    }, theme.breakpoints.up('md'), {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)'
    }),
    accordionAttachments: {
      marginTop: 0
    },
    divider: {
      margin: theme.spacing(4, 0, 1, 0)
    },
    accordionSummary: _objectSpread(_objectSpread({}, theme.border({
      width: 0,
      radius: 6
    })), {}, {
      backgroundColor: theme.palette.common.white,
      margin: theme.spacing(0, -1),
      minHeight: 0,
      padding: theme.spacing(1.5),
      '&$expanded': {
        backgroundColor: theme.palette.common.white,
        minHeight: 0
      }
    }),
    accordionItem: _objectSpread(_objectSpread({}, theme.border({
      kind: 'bottom',
      color: 'transparent',
      radius: 0
    })), {}, {
      margin: 0,
      padding: theme.spacing(1, 0),
      boxShadow: 'none',
      background: 'none',
      transition: theme.transitions.create('padding'),
      '&::before': {
        display: 'none'
      },
      '&:first-child': {
        margin: 0,
        borderRadius: 0
      },
      '&:last-child': {
        margin: 0,
        borderRadius: 0
      },
      '&$expanded': {
        margin: 0,
        padding: theme.spacing(2, 0)
      },
      '&$expanded:first-child': {
        padding: theme.spacing(1, 0, 2)
      }
    })
  };
});
export default useStyles;