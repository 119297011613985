import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Types, Queries, getCountryCode3, getMembershipsToShow, isFeatureEnabled, getCountryCode, formatters, ContentTransformers } from 'common';
import { useIntl } from 'react-intl';
import Dayjs from 'dayjs';
import { useEventPriceListQuery } from '../graphql/queries';
import contentUtils from './content';
import attachmentUtils from './attachment';

var getDays = function getDays(schedules) {
  return (schedules === null || schedules === void 0 ? void 0 : schedules.filter(function (each) {
    return each.scheduleType === Types.ProgramScheduleType.Day;
  })) || [];
};

var scheduleTypeOrder = [Types.ProgramScheduleType.Day, Types.ProgramScheduleType.BeginEnd, Types.ProgramScheduleType.Block, Types.ProgramScheduleType.Registration, Types.ProgramScheduleType.TopicBlock, Types.ProgramScheduleType.Lecture, Types.ProgramScheduleType.SponsoredLecture, Types.ProgramScheduleType.Organizational, Types.ProgramScheduleType.Workshop];
/**
 * this function is reponsible for sorting the schedules of each product in a way
 * that they should be shown in the program section
 * @param schedules: this is the list of each products schedules
 * @returnType: will be the sorted list of each products schedules
 *
 * The first sorting param is date, if the dates are equal this function will use the
 * scheduleTypeOrder array to see which one should be go first
 * the scheduleTypeOrder is ordered from high to low priority
 */

var sortSchedules = function sortSchedules(schedules) {
  if (!Array.isArray(schedules) || schedules.length < 1) {
    return schedules;
  }

  var sortedArray = _toConsumableArray(schedules).sort(function (first, second) {
    var dateDiff = Dayjs(first.startDateTime).diff(second.startDateTime);

    if (dateDiff !== 0) {
      return dateDiff;
    }

    if (!first.scheduleType || !second.scheduleType) {
      return 0;
    }

    return scheduleTypeOrder.indexOf(first.scheduleType) - scheduleTypeOrder.indexOf(second.scheduleType);
  });

  return sortedArray;
};
/**
 * this function will return the list of schedules for a day
 * @param {string} day: string - the selected day date string
 * @param {Array<ProgramSchedules>} schedules: list of the schedules of the product
 * @returnType: all the schedules for a specific day
 */


var getDaySchedules = function getDaySchedules(day, schedules) {
  if (!schedules || !day) {
    return [];
  }

  var dateString = Dayjs(day);
  return schedules.filter(function (each) {
    return Dayjs(each.startDateTime).isSame(dateString, 'day');
  });
};
/**
 * Schedules with beginEnd type can be begin of the block or end of the block
 * this function will indicate if the schedule is the end or not
 * @param {ProgramSchedule} a program schedule
 * @returnType if this schedule is of type BeginEnd and the end schedule
 */


var isEndSchedule = function isEndSchedule(scheduleType, title) {
  if (scheduleType !== Types.ProgramScheduleType.BeginEnd) {
    return false;
  } // TODO find a clean way to handle this.


  return title === null || title === void 0 ? void 0 : title.toLowerCase().includes('end');
};
/**
 * This function will receive the event's schedules and a specific date
 * and will filter those schedules with 2 conditions =>
 * 1. the type of schedule should be topic-block
 * 2. the day of schedule should be the same as the specified date
 */


var getTopicBlocksForDay = function getTopicBlocksForDay(day, schedules) {
  var daySchedules = getDaySchedules(day, schedules);
  return daySchedules.filter(function (each) {
    return each.scheduleType === Types.ProgramScheduleType.TopicBlock;
  });
};

export var isPriceSectionVisible = function isPriceSectionVisible(content, priceList) {
  var _content$detailsPageS, _priceList$items;

  return (content === null || content === void 0 ? void 0 : (_content$detailsPageS = content.detailsPageSettings) === null || _content$detailsPageS === void 0 ? void 0 : _content$detailsPageS.eventBookable) === true && Boolean(priceList === null || priceList === void 0 ? void 0 : (_priceList$items = priceList.items) === null || _priceList$items === void 0 ? void 0 : _priceList$items.length) && content.contentType !== Types.ContentType.Webinar && Boolean((priceList === null || priceList === void 0 ? void 0 : priceList.items.some(function (priceItem) {
    return priceItem.earlyBirdPrice !== 0 || priceItem.normalPrice !== 0 || priceItem.latePrice !== 0;
  })) || (priceList === null || priceList === void 0 ? void 0 : priceList.printedCourseBookPrice));
};
export var isMembershipSectionVisible = function isMembershipSectionVisible(locale, memberships) {
  return isFeatureEnabled('memberships', getCountryCode(locale)) && Boolean(memberships === null || memberships === void 0 ? void 0 : memberships.length);
};
export var isSectionVisible = function isSectionVisible(section, _ref) {
  var _contentUtils$getTarg, _content$sponsorships, _content$agendaSettin2, _content$programSched2, _content$accreditatio;

  var content = _ref.content,
      priceList = _ref.priceList,
      isEventBooked = _ref.isEventBooked,
      locale = _ref.locale,
      isMember = _ref.isMember;

  switch (section) {
    case 'Overview':
      return true;

    case 'Speakers':
      {
        var _content$programSched, _content$agendaSettin;

        var speakers = contentUtils.getSpeakers((_content$programSched = content === null || content === void 0 ? void 0 : content.programSchedules) !== null && _content$programSched !== void 0 ? _content$programSched : []);
        return (content === null || content === void 0 ? void 0 : (_content$agendaSettin = content.agendaSettings) === null || _content$agendaSettin === void 0 ? void 0 : _content$agendaSettin.showSpeakers) === true && (speakers !== null && speakers !== void 0 ? speakers : []).length > 0;
      }

    case 'Membership':
      return isMember === false;

    case 'Price':
      return isPriceSectionVisible(content, priceList);

    case 'TargetGroups':
      return Boolean((_contentUtils$getTarg = contentUtils.getTargetGroups(locale, content)) === null || _contentUtils$getTarg === void 0 ? void 0 : _contentUtils$getTarg.length);

    case 'Sponsors':
      return ((_content$sponsorships = content === null || content === void 0 ? void 0 : content.sponsorships) !== null && _content$sponsorships !== void 0 ? _content$sponsorships : []).some(function (sponsorship) {
        var _sponsorship$sponsorC, _sponsorship$sponsorC2;

        return (_sponsorship$sponsorC = sponsorship.sponsorCompany) === null || _sponsorship$sponsorC === void 0 ? void 0 : (_sponsorship$sponsorC2 = _sponsorship$sponsorC.name) === null || _sponsorship$sponsorC2 === void 0 ? void 0 : _sponsorship$sponsorC2.length;
      });

    case 'Program':
      return (content === null || content === void 0 ? void 0 : (_content$agendaSettin2 = content.agendaSettings) === null || _content$agendaSettin2 === void 0 ? void 0 : _content$agendaSettin2.showAgenda) === true && ((_content$programSched2 = content === null || content === void 0 ? void 0 : content.programSchedules) !== null && _content$programSched2 !== void 0 ? _content$programSched2 : []).length > 0;

    case 'Accreditation':
      return (content === null || content === void 0 ? void 0 : (_content$accreditatio = content.accreditationSettings) === null || _content$accreditatio === void 0 ? void 0 : _content$accreditatio.showSection) === true;

    case 'Downloads':
      return attachmentUtils.getAttachments({
        content: content,
        isEventBooked: isEventBooked
      }).length > 0;

    default:
      return false;
  }
};
export var usePriceData = function usePriceData(content) {
  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  var _useEventPriceListQue = useEventPriceListQuery({
    variables: {
      ubEventId: (content === null || content === void 0 ? void 0 : content.externalId) || ''
    },
    skip: !content
  }),
      priceListData = _useEventPriceListQue.data;

  var _Queries$useGetMember = Queries.useGetMembershipsQuery({
    variables: {
      country: getCountryCode3(locale)
    }
  }),
      membershipsData = _Queries$useGetMember.data;

  var memberships = getMembershipsToShow((membershipsData === null || membershipsData === void 0 ? void 0 : membershipsData.memberships) || [], [Types.OccupationType.Doctor, Types.OccupationType.AssistantDoctor]);
  return {
    priceList: priceListData === null || priceListData === void 0 ? void 0 : priceListData.eventPriceList,
    memberships: memberships
  };
};

var getMaxCredit = function getMaxCredit(content) {
  if (!content) {
    return null;
  }

  var maxCredit = null;
  content.credits.forEach(function (credit) {
    var _maxCredit$amount, _maxCredit;

    if (credit.amount > ((_maxCredit$amount = (_maxCredit = maxCredit) === null || _maxCredit === void 0 ? void 0 : _maxCredit.amount) !== null && _maxCredit$amount !== void 0 ? _maxCredit$amount : 0)) {
      maxCredit = credit;
    }
  });
  return maxCredit;
};

export var generateTemplateValues = function generateTemplateValues(countryCode, content) {
  var _maxCME$unit;

  var maxCME = getMaxCredit(content);
  var unit = (_maxCME$unit = maxCME === null || maxCME === void 0 ? void 0 : maxCME.unit) !== null && _maxCME$unit !== void 0 ? _maxCME$unit : undefined;

  if (!(maxCME !== null && maxCME !== void 0 && maxCME.isApproved)) {
    if (countryCode === 'ch') {
      unit = 'h';
    } else {
      unit += ' *';
    }
  }

  return {
    CME: maxCME !== null && maxCME !== void 0 && maxCME.amount ? "".concat(maxCME.amount) : undefined,
    CME_UNIT: unit
  };
};
export var getCredits = function getCredits(_ref2) {
  var countryCode = _ref2.countryCode,
      participationOption = _ref2.participationOption,
      credits = _ref2.credits;

  if (countryCode !== 'ch') {
    return credits;
  } // For CH we only show the approved credits


  var approvedCredits = credits.filter(function (each) {
    return each.isApproved;
  });

  if (!approvedCredits) {
    return approvedCredits;
  } // if there are onsite credits with unit x
  // and there is no livestream credit with unit x
  // and the type of content is hybrid or livestream
  // we use the onsite credit both for onsite and livestream


  if (participationOption !== Types.ContentParticipationOption.Hybrid && participationOption !== Types.ContentParticipationOption.LiveStream) {
    return approvedCredits;
  }

  var onSiteCreditsWithoutLivestream = approvedCredits.filter(function (credit) {
    return credit.participationType === Types.ParticipationType.OnSite;
  }).filter(function (onsite) {
    return !credits.find(function (each) {
      return each.participationType === Types.ParticipationType.Livestream && each.unit === onsite.unit;
    });
  });
  return [].concat(_toConsumableArray(credits), _toConsumableArray(onSiteCreditsWithoutLivestream.map(function (each) {
    return _objectSpread(_objectSpread({}, each), {}, {
      participationType: Types.ParticipationType.Livestream
    });
  })));
};
export var programUtils = {
  getDays: getDays,
  sortSchedules: sortSchedules,
  getDaySchedules: getDaySchedules,
  isEndSchedule: isEndSchedule,
  getTopicBlocksForDay: getTopicBlocksForDay
};
export var getContentTitle = function getContentTitle(content, locale, messages) {
  var _content$product;

  var title = [formatters.formatTranslation((_content$product = content.product) === null || _content$product === void 0 ? void 0 : _content$product.name, {
    locale: locale
  }), formatters.formatEnum(content.brand, {
    options: ContentTransformers.brand,
    messages: messages
  }), formatters.formatTranslation(content.city, {
    locale: locale
  })].filter(Boolean).join(' ');
  return title;
};