import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(3)
    },
    button: _defineProperty({
      width: '100%',
      borderRadius: 6,
      textTransform: 'none',
      paddingBlock: function paddingBlock(props) {
        return props.withPadding ? theme.spacing(3) : 0;
      }
    }, theme.breakpoints.up('sm'), {
      paddingBlock: function paddingBlock(props) {
        return props.withPadding ? theme.spacing(0) : undefined;
      }
    }),
    secondaryButton: _defineProperty({
      width: '100%',
      borderRadius: 6,
      textTransform: 'none',
      backgroundColor: theme.palette.error.dark,
      paddingBlock: theme.spacing(3),
      whiteSpace: function whiteSpace(props) {
        return props.secondaryButtonNoWrap ? 'nowrap' : 'normal';
      }
    }, theme.breakpoints.up('sm'), {
      paddingBlock: theme.spacing(0)
    }),
    minimalButtonIcon: {
      backgroundColor: theme.palette.error.dark,
      width: 13,
      height: 13,
      borderRadius: '50%'
    },
    secondaryMinimalButtonIcon: {
      backgroundColor: 'white',
      width: 13,
      height: 13,
      borderRadius: '50%'
    },
    alreadyBookedIcon: {
      width: 24,
      height: 24
    },
    buttonStartIcon: {
      marginRight: theme.spacing(1)
    },
    icon: {
      width: 24,
      height: 24
    },
    alreadyBookedButton: {
      '&$button': {
        color: theme.palette.success[500],
        backgroundColor: "".concat(theme.palette.success[500], "1a"),
        '&:hover': {
          color: theme.palette.success[500],
          backgroundColor: "".concat(theme.palette.success[500], "1a"),
          cursor: 'initial'
        }
      }
    },
    timerDivider: {
      borderColor: theme.palette.line[100],
      margin: theme.spacing(3, -3, 0, -3)
    },
    nonAvailableForMembersContainer: {
      display: 'flex'
    },
    notIncludedInMembership: _objectSpread(_objectSpread({}, theme.typography.body2), {}, {
      color: '#673AB7'
    }),
    infoWarningIcon: {
      color: '#673AB7',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5)
    },
    addToCalendarContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    }
  };
}, // overriding button styles
{
  name: 'Buttons',
  index: 10
});
export default useStyles;