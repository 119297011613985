import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["specialties"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Types, booleanFilter, getContentNameForTracking } from 'common';
import _uniq from 'lodash/uniq';
import { contentUtils } from '../../index';
export var transformContentDocumentsToProductList = function transformContentDocumentsToProductList(contentsForAnalytics, listId, locale) {
  var products = contentsForAnalytics.map(function (contentForAnalytics, index) {
    var _transformContentDocu = transformContentDocumentToProduct(contentForAnalytics, {
      locale: locale,
      index: index
    }),
        specialties = _transformContentDocu.specialties,
        product = _objectWithoutProperties(_transformContentDocu, _excluded);

    return _objectSpread(_objectSpread({}, product), {}, {
      position: index + 1
    });
  });

  var productsCategories = _uniq(products.map(function (product) {
    return product.category;
  }));

  var productsBrands = _uniq(products.map(function (product) {
    return product.brand;
  })).filter(booleanFilter);

  return {
    list_id: listId,
    brands: productsBrands,
    categories: productsCategories,
    products: products
  };
};
export var transformContentDocumentToProduct = function transformContentDocumentToProduct(contentForAnalytics, params) {
  var _content$product;

  var parent = contentForAnalytics.parent;
  var locale = params.locale,
      index = params.index;
  var content = {
    id: contentForAnalytics.id,
    contentId: contentForAnalytics.contentId,
    brand: contentForAnalytics.brand || (parent === null || parent === void 0 ? void 0 : parent.brand),
    city: contentForAnalytics.city || (parent === null || parent === void 0 ? void 0 : parent.city),
    contentType: contentForAnalytics.contentType || (parent === null || parent === void 0 ? void 0 : parent.contentType),
    specialities: contentForAnalytics.specialities || (parent === null || parent === void 0 ? void 0 : parent.specialities),
    product: contentForAnalytics.product || (parent === null || parent === void 0 ? void 0 : parent.product),
    title: contentForAnalytics.title || (parent === null || parent === void 0 ? void 0 : parent.title),
    partners: (contentForAnalytics.partnerships || (parent === null || parent === void 0 ? void 0 : parent.partnerships) || []).map(function (partnership) {
      return partnership.partnerCompany;
    }).filter(booleanFilter),
    startDateTime: (parent === null || parent === void 0 ? void 0 : parent.startDateTime) || contentForAnalytics.startDateTime
  };
  var city = content.city;
  var isVideo = contentUtils.isVideoContent(content);
  var isDocument = contentUtils.isDocumentContent(content);
  var brand = content.brand || 'UPDATE_REFRESHER';

  var category = function () {
    if (isDocument) {
      return 'DOCUMENT';
    } else if (isVideo) {
      return 'VIDEO';
    } else if (content.contentType === Types.ContentType.Webinar) {
      return 'WEBINAR';
    } else {
      return 'COURSE';
    }
  }();

  return _objectSpread({
    content_id: content.contentId,
    brand: brand,
    category: category,
    location: city && city[locale] || '',
    // setting it to -1 since we don't really need the price for content viewed or content added events, only for events on booking page
    price: -1,
    variant: 'HYBRID',
    specialties: content.specialities,
    main_speciality: ((_content$product = content.product) === null || _content$product === void 0 ? void 0 : _content$product.code) || '',
    name: getContentNameForTracking(content, locale),
    has_partnership: content.partners.length > 0,
    partnerships: content.partners.map(function (partner) {
      return {
        partner_ub_account_code: partner.ubAccountCode || undefined,
        partner_name: partner.name || undefined
      };
    })
  }, typeof index === 'number' ? {
    position: index + 1
  } : undefined);
};