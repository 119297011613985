import _toConsumableArray from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { Types } from 'common';
import { formatDateForDB } from 'components';
import Dayjs from 'dayjs';
export var getVideosSearchQuery = function getVideosSearchQuery(_ref) {
  var companyIds = _ref.companyIds,
      departmentIds = _ref.departmentIds,
      orders = _ref.orders,
      _ref$pageNumber = _ref.pageNumber,
      pageNumber = _ref$pageNumber === void 0 ? 0 : _ref$pageNumber,
      pageSize = _ref.pageSize,
      contentType = _ref.contentType,
      contentIdsToExclude = _ref.contentIdsToExclude;
  return {
    searchQuery: {
      filters: [{
        field: 'sponsoredBy',
        operation: Types.SearchFilterOperation.Equal,
        value: companyIds,
        dataType: Types.SearchFilterDataType.Uuid
      }, {
        field: 'contentType',
        operation: Types.SearchFilterOperation.Equal,
        value: contentType || [Types.ContentType.Lecture],
        dataType: Types.SearchFilterDataType.Enum
      }, {
        operation: Types.SearchFilterOperation.GreaterThan,
        field: 'validDate',
        value: [formatDateForDB(Dayjs.utc().endOf('hour'))]
      }, {
        operation: Types.SearchFilterOperation.Equal,
        field: 'isAvailableOnPartnerForum',
        value: ['true'],
        dataType: Types.SearchFilterDataType.Boolean
      }].concat(_toConsumableArray(departmentIds ? [{
        field: 'sponsoredByDepartment',
        operation: Types.SearchFilterOperation.Equal,
        value: departmentIds,
        dataType: Types.SearchFilterDataType.Uuid
      }] : []), _toConsumableArray(contentIdsToExclude && contentIdsToExclude.length > 0 ? _toConsumableArray(contentIdsToExclude.map(function (contentId) {
        return {
          field: 'contentId',
          operation: Types.SearchFilterOperation.NotEqual,
          value: [contentId]
        };
      })) : [])),
      orders: orders,
      pageNumber: pageNumber,
      pageSize: pageSize
    }
  };
};
export var getPartnerForumLecturesVariables = function getPartnerForumLecturesVariables(companyId, departmentIds) {
  return getVideosSearchQuery({
    companyIds: [companyId],
    departmentIds: departmentIds,
    orders: ['-startDateTime']
  });
};
export var getDocumentsSearchQuery = function getDocumentsSearchQuery(_ref2) {
  var providerId = _ref2.providerId,
      departmentId = _ref2.departmentId,
      contentType = _ref2.contentType,
      isCommercial = _ref2.isCommercial,
      _ref2$pageNumber = _ref2.pageNumber,
      pageNumber = _ref2$pageNumber === void 0 ? 0 : _ref2$pageNumber,
      orders = _ref2.orders,
      pageSize = _ref2.pageSize;
  return {
    searchQuery: {
      filters: [departmentId ? {
        field: 'departmentId',
        operation: Types.SearchFilterOperation.Equal,
        value: departmentId,
        dataType: Types.SearchFilterDataType.Uuid
      } : {
        field: 'providerId',
        operation: Types.SearchFilterOperation.Equal,
        value: providerId,
        dataType: Types.SearchFilterDataType.Uuid
      }, {
        field: 'contentType',
        operation: Types.SearchFilterOperation.Equal,
        value: contentType,
        dataType: Types.SearchFilterDataType.Enum
      }, {
        field: 'isCommercial',
        operation: Types.SearchFilterOperation.Equal,
        dataType: Types.SearchFilterDataType.Boolean,
        value: isCommercial
      }],
      pageNumber: pageNumber,
      orders: orders,
      pageSize: pageSize
    }
  };
};