import _taggedTemplateLiteral from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { gql } from '@apollo/client';
import { env, Fragments } from 'common';
export var EventPriceList = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    ", "\n    query EventPriceList($ubEventId: String!, $tenantId: ID = \"", "\") {\n        eventPriceList(ubEventId: $ubEventId, tenantId: $tenantId) {\n            ...PriceListFields\n        }\n    }\n"])), Fragments.PriceListFields, env.TENANT_ID);
export var ContentDocumentsExtended = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t", "\n\t", "\n\t", "\n\t", "\n\t", "\n\tquery ContentDocumentsExtended($searchQuery: ContentSearchInput!) {\n\t\tcontentDocuments(searchQuery: $searchQuery) {\n\t\t\tdata {\n\t\t\t\t__typename\n\t\t\t\tid\n\t\t\t\tbrand\n\t\t\t\tendDateTime\n\t\t\t\texternalId\n\t\t\t\tcontentId\n\t\t\t\tcontentType\n\t\t\t\tsource {\n\t\t\t\t\t...SourceFields\n\t\t\t\t}\n\t\t\t\tstartDateTime\n\t\t\t\twillBeBroadcasted\n\t\t\t\tisFree\n\t\t\t\tisAvailableOnHomepage\n\t\t\t\tparticipationOption\n\t\t\t\tcapacityStatus\n\t\t\t\tlocale\n\t\t\t\ttitle\n\t\t\t\tcity\n\t\t\t\tsubtitle\n\t\t\t\tslug\n\t\t\t\tprovider {\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tcredits {\n\t\t\t\t\t...ContentCreditFields\n\t\t\t\t}\n\t\t\t\tspeaker {\n\t\t\t\t\t...SpeakerFields\n\t\t\t\t}\n\t\t\t\tscientificLeaders {\n\t\t\t\t\t...SpeakerFields\n\t\t\t\t}\n\t\t\t\ttopics {\n\t\t\t\t\t...TopicFields\n\t\t\t\t}\n\t\t\t\tparent {\n\t\t\t\t\tlocale\n\t\t\t\t\tisFree\n\t\t\t\t\tcontentId\n\t\t\t\t\tcontentType\n\t\t\t\t}\n\t\t\t\tproduct {\n\t\t\t\t\t..._ProductDocumentFields\n\t\t\t\t}\n\t\t\t\tspecialities\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n"])), Fragments.ContentCreditFields, Fragments.SpeakerFields, Fragments.TopicFields, Fragments.SourceFields, Fragments._ProductDocumentFields);
export var ContentsWithProgramSchedules = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t", "\n\tquery ContentsWithProgramSchedules($searchQuery: SearchQuery!) {\n\t\tcontents(searchQuery: $searchQuery) {\n\t\t\tdata {\n\t\t\t\t...ContentWithProgramSchedulesFields\n\t\t\t}\n\t\t\ttotalCount\n\t\t\tpageNumber\n\t\t\tpageSize\n\t\t}\n\t}\n"])), Fragments.ContentWithProgramSchedulesFields);
export var PartnerForumAllCompanyVideos = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\tquery PartnerForumAllCompanyVideos($searchQuery: SearchQuery!) {\n\t\tcontents(searchQuery: $searchQuery) {\n\t\t\tdata {\n\t\t\t\tid\n\t\t\t\tcontentId\n\t\t\t\tcontentType\n\t\t\t\treplacementDate\n\t\t\t\tbrand\n\t\t\t\theaderUrl\n\t\t\t\tstartDateTime\n\t\t\t\tendDateTime\n\t\t\t\ttitle\n\t\t\t\tisFree\n\t\t\t\tisCommercial\n\t\t\t\torder\n\t\t\t\trating\n\t\t\t\tcontentUnit {\n\t\t\t\t\torder\n\t\t\t\t\ttitle\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t\tspeakers {\n\t\t\t\t\tid\n\t\t\t\t\ttitle\n\t\t\t\t\tname\n\t\t\t\t\tavatarSrcUrl\n\t\t\t\t\tdescription\n\t\t\t\t}\n\t\t\t\tsource {\n\t\t\t\t\tduration\n\t\t\t\t\tfile {\n\t\t\t\t\t\tdownloadUrl\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t\turl\n\t\t\t\t\tthumbnail\n\t\t\t\t}\n\t\t\t\tproduct {\n\t\t\t\t\tname\n\t\t\t\t\tcode\n\t\t\t\t\tspecialityIconUrl\n\t\t\t\t}\n\t\t\t\tprovider {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tlogoUrl\n\t\t\t\t}\n\t\t\t\tdepartment {\n\t\t\t\t\tlogoUrl\n\t\t\t\t}\n\t\t\t\tcontentProgress {\n\t\t\t\t\tcompleted\n\t\t\t\t\ttime\n\t\t\t\t}\n\t\t\t\tisSponsored\n\t\t\t\tisAvailableOnMediathek\n\t\t\t\tisAvailableWithoutEfn\n\t\t\t\tsponsorships {\n\t\t\t\t\tparticipantRestriction\n\t\t\t\t\tsponsorCompany {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tlogoUrl\n\t\t\t\t\t}\n\t\t\t\t\tsponsorDepartments {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t\tlogoUrl\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\tparent {\n\t\t\t\t\tisFree\n\t\t\t\t\tsponsorships {\n\t\t\t\t\t\tsponsorCompany {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\tlogoUrl\n\t\t\t\t\t\t}\n\t\t\t\t\t\tsponsorDepartments {\n\t\t\t\t\t\t\tid\n\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\tlogoUrl\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotalCount\n\t\t\tpageNumber\n\t\t\tpageSize\n\t\t}\n\t}\n"])));
export var PartnerForumOverviewVideos = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\tquery PartnerForumOverviewVideos($searchQuery: SearchQuery!) {\n\t\tcontents(searchQuery: $searchQuery) {\n\t\t\tdata {\n\t\t\t\tid\n\t\t\t\tcontentType\n\t\t\t\tcontentId\n\t\t\t\tbrand\n\t\t\t\tstartDateTime\n\t\t\t\ttitle\n\t\t\t\tisCommercial\n\t\t\t\trating\n\t\t\t\tparent {\n\t\t\t\t\ttitle\n\t\t\t\t\tbrand\n\t\t\t\t\tcity\n\t\t\t\t\tcontentType\n\t\t\t\t\tstartDateTime\n\t\t\t\t}\n\t\t\t\tsource {\n\t\t\t\t\tthumbnail\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotalCount\n\t\t\tpageNumber\n\t\t\tpageSize\n\t\t}\n\t}\n"])));
export var SimpleContents = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\tquery SimpleContents($searchQuery: SearchQuery!) {\n\t\tcontents(searchQuery: $searchQuery) {\n\t\t\tdata {\n\t\t\t\tid\n\t\t\t\tbrand\n\t\t\t\ttitle\n\t\t\t\texternalId\n\t\t\t\tcontentId\n\t\t\t\tsubtitle\n\t\t\t}\n\t\t\ttotalCount\n\t\t\tpageNumber\n\t\t\tpageSize\n\t\t}\n\t}\n"])));
export var GetCompany = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\tquery GetCompany($id: ID, $slug: String, $country: String) {\n\t\tcompany(id: $id, slug: $slug, country: $country) {\n\t\t\tid\n\t\t\tname\n\t\t\tlogoUrl\n\t\t\tdescription\n\t\t\tcity\n\t\t\tcountry\n\t\t\tpostalCode\n\t\t\tphone\n\t\t\temail\n\t\t\twebsites\n\t\t\tubAccountCode\n\t\t\taddress\n\t\t\tcompanyType\n\t\t\tlinks {\n\t\t\t\tname\n\t\t\t\ttype\n\t\t\t\turl\n\t\t\t}\n\t\t\tfax\n\t\t\tcompanyPage {\n\t\t\t\tpublishStatus\n\t\t\t\tdescriptionHeader\n\t\t\t\tdescriptionBody\n\t\t\t\tshortLink\n\t\t\t\tdirectLink\n\t\t\t\tslug\n\t\t\t\tisContactSectionHidden\n\t\t\t\tcustomTitle\n\t\t\t}\n\t\t\tdepartments {\n\t\t\t\tid\n\t\t\t\ttranslatedName\n\t\t\t\tlogoUrl\n\t\t\t\tlinks {\n\t\t\t\t\tname\n\t\t\t\t\ttype\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t\tcompanyContacts {\n\t\t\t\t\tname\n\t\t\t\t\temail\n\t\t\t\t\tdescription\n\t\t\t\t\ttranslatedDescription\n\t\t\t\t\tphone\n\t\t\t\t\tavatarSrcUrl\n\t\t\t\t}\n\t\t\t\tcompanyPage {\n\t\t\t\t\tpublishStatus\n\t\t\t\t\tdescriptionHeader\n\t\t\t\t\tdescriptionBody\n\t\t\t\t\tshortLink\n\t\t\t\t\tdirectLink\n\t\t\t\t\tslug\n\t\t\t\t\tisContactSectionHidden\n\t\t\t\t\tcustomTitle\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
export var MediathekCourses = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\tquery MediathekCourses($searchQuery: ContentSearchInput!) {\n\t\tcontentDocuments(searchQuery: $searchQuery) {\n\t\t\tdata {\n\t\t\t\tbrand\n\t\t\t\tcontentId\n\t\t\t\tslug\n\t\t\t\texternalId\n\t\t\t\tstartDateTime\n\t\t\t\tendDateTime\n\t\t\t\tcity\n\t\t\t\tid\n\t\t\t\tlocale\n\t\t\t\tcontentType\n\t\t\t\tproduct {\n\t\t\t\t\tid\n\t\t\t\t\tname\n\t\t\t\t\tcode\n\t\t\t\t\tbrand\n\t\t\t\t\tspecialityIconUrl\n\t\t\t\t\ticonPrimaryColor\n\t\t\t\t\ttopics {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tcode\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\twillBeBroadcasted\n\t\t\t\tparticipationOption\n\t\t\t\tcapacityStatus\n\t\t\t\tcredits {\n\t\t\t\t\tparticipationType\n\t\t\t\t\tisApproved\n\t\t\t\t\tamount\n\t\t\t\t\tunit\n\t\t\t\t}\n\t\t\t\tspecialities\n\t\t\t\ttitle\n\t\t\t}\n\t\t\taggregations {\n\t\t\t\tbuckets {\n\t\t\t\t\tkey\n\t\t\t\t\tvalue\n\t\t\t\t}\n\t\t\t\tname\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n"])));