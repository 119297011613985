var SPEAKER_LIMIT_MOBILE = 4;
var SPEAKER_LIMIT_DESKTOP = 6;
export var isLimitReached = function isLimitReached(speakers, isDesktop) {
  return isDesktop ? (speakers !== null && speakers !== void 0 ? speakers : []).length > SPEAKER_LIMIT_DESKTOP : (speakers !== null && speakers !== void 0 ? speakers : []).length > SPEAKER_LIMIT_MOBILE;
};
export var applySpeakerLimit = function applySpeakerLimit(speakers, isDesktop, shouldShowAll) {
  if (!isLimitReached(speakers, isDesktop) || shouldShowAll) {
    return speakers;
  }

  return speakers === null || speakers === void 0 ? void 0 : speakers.filter(function (_, idx) {
    return idx < (isDesktop ? SPEAKER_LIMIT_DESKTOP : SPEAKER_LIMIT_MOBILE);
  });
};