import { CATALOG_URLS, Types, buildURLWithParams, generateContentURL, useChangeURL } from 'common';
import { useCallback } from 'react';
export var buildPartnerContentURL = function buildPartnerContentURL(_ref) {
  var slug = _ref.slug,
      providerId = _ref.providerId,
      contentId = _ref.contentId;
  var newUrl = buildURLWithParams(CATALOG_URLS.partner, [slug || providerId || '', 'all']);
  newUrl += "/videos/".concat(contentId);
  return newUrl;
};

/**
 * @returns {Function} onClick function for partner content
 * Useful to navigate to partner content page relying on the provider `slug` or `providerId` as fallback.
 *
 * @deprecated Don't use onClick functions to change the URL, instead generate the URL and pass it to the Link component for better accessibility.
 */
var useOnPartnerContentClick = function useOnPartnerContentClick() {
  var changeURL = useChangeURL();
  var onClickFn = useCallback(function (_ref2) {
    var isInTheFuture = _ref2.isInTheFuture,
        brand = _ref2.brand,
        contentId = _ref2.contentId,
        slug = _ref2.slug,
        providerId = _ref2.providerId;

    if (isInTheFuture && brand !== Types.ProductBrand.Summedup) {
      var contentUrl = generateContentURL({
        contentId: contentId
      });
      changeURL(contentUrl, 'catalog', true);
    } else {
      var partnerContentUrl = buildPartnerContentURL({
        slug: slug,
        providerId: providerId,
        contentId: contentId
      });
      changeURL(partnerContentUrl, 'catalog', true);
    }
  }, []);
  return onClickFn;
};

export default useOnPartnerContentClick;