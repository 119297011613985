import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { makeStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) {
  return {
    speakersContainer: _defineProperty({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(4),
      marginTop: theme.spacing(3)
    }, theme.breakpoints.up('sm'), {
      gridGap: theme.spacing(5),
      gridTemplateColumns: 'repeat(2, 1fr)'
    }),
    speakerAvatar: _defineProperty({
      width: 64,
      height: 64
    }, theme.breakpoints.up('sm'), {
      width: 72,
      height: 72
    }),
    speakerTitle: {
      fontWeight: 600,
      fontFamily: 'Poppins',
      fontSize: 16,
      lineHeight: '24px'
    },
    speakerDetails: {
      marginLeft: theme.spacing(2.5)
    },
    speakerDescription: {
      opacity: 0.6,
      color: theme.palette.common.black,
      lineHeight: '22px'
    },
    showAllButton: _defineProperty({
      margin: theme.spacing(4, 'auto', 0, 'auto'),
      textTransform: 'none'
    }, theme.breakpoints.up('sm'), {
      marginTop: theme.spacing(8)
    })
  };
}, // this is being used to override buttons styles
{
  name: 'ProductDetailsSpeakers',
  index: 10
});
export default useStyles;