import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback } from 'react';
import { Types, buildURLWithParams, MEDIATHEK_URLS, useChangeURL, CATALOG_URLS } from 'common';
import { contentUtils } from '../utils';
import { catalogTracking } from '..';
import { useIntl } from 'react-intl';

/**
 * Generates URL for a video content. If the videos is part of a course, the URL will point to the video in the playlist of that course, otherwise it will point to the video itself.
 */
export var generateContentWithCourseURL = function generateContentWithCourseURL(content) {
  var _content$parent, _content$parent2, _parentId;

  var contentId = content.contentId;
  var parentId = null;

  if (content.contentType === Types.ContentType.Lecture && (((_content$parent = content.parent) === null || _content$parent === void 0 ? void 0 : _content$parent.contentType) === Types.ContentType.Course || ((_content$parent2 = content.parent) === null || _content$parent2 === void 0 ? void 0 : _content$parent2.contentType) === Types.ContentType.Webinar)) {
    parentId = content.parent.contentId;
  }

  var newUrl = buildURLWithParams(MEDIATHEK_URLS.content, [(_parentId = parentId) !== null && _parentId !== void 0 ? _parentId : contentId]);
  var videoPlayingUrlPath = "/".concat(content.contentId);

  if (parentId) {
    newUrl += videoPlayingUrlPath;
  }

  return newUrl;
};
export var generateContentURL = function generateContentURL(content) {
  var isLive = contentUtils.isLive({
    startDateTime: content.startDateTime,
    endDateTime: content.endDateTime
  });

  if ((isLive || contentUtils.isInTheFuture(content)) && content.brand !== Types.ProductBrand.Summedup) {
    var _content$slug;

    var contentUrl = buildURLWithParams(CATALOG_URLS.content, [(_content$slug = content.slug) !== null && _content$slug !== void 0 ? _content$slug : content.contentId]);
    return contentUrl;
  } else {
    return generateContentWithCourseURL(content);
  }
};

var useOnContentClick = function useOnContentClick() {
  var changeURL = useChangeURL();

  var _useIntl = useIntl(),
      locale = _useIntl.locale;

  var onClickFn = useCallback(function (content, params) {
    return function () {
      var url = generateContentURL(content);
      changeURL(url, 'catalog', true);
      catalogTracking.contentClicked(content.contentId, _objectSpread(_objectSpread({}, params), {}, {
        locale: locale
      }));
    };
  }, []);
  return onClickFn;
};

export default useOnContentClick;