import _toConsumableArray from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import Dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import { Types, getCountryCode, formatters, ContentTransformers, DrupalEventTransformers } from 'common';
import { DateFormat } from 'components';
import { contentUtils } from '../index';

var isLive = function isLive(_ref) {
  var startDateTime = _ref.startDateTime,
      endDateTime = _ref.endDateTime;
  var startDate = Dayjs(startDateTime);
  var endDate = Dayjs(endDateTime);
  return startDate.isBefore(Dayjs()) && endDate.isAfter(Dayjs());
};

var isInTheFuture = function isInTheFuture(_ref2) {
  var startDateTime = _ref2.startDateTime;
  var startDate = Dayjs(startDateTime);
  return !startDate.isBefore(Dayjs());
};

var generateCreditString = function generateCreditString(credit, locale) {
  // show nothing if amount is `0` but proceed for `0 DMP` case
  if (!credit.amount && credit.unit !== 'DMP') {
    return null;
  }

  var countryCode = getCountryCode(locale);
  var creditString = '';

  if (countryCode !== 'ch') {
    creditString = "".concat(credit.amount, " ").concat(credit.unit).concat(!credit.isApproved ? '*' : ''); // replace `0 DMP` with `DMP-Update`

    creditString = creditString.replace(/^(0 DMP)/, 'DMP-Update');
  } else {
    if (!credit.isApproved && credit.unit === 'SGUM') {
      return null;
    }

    creditString = "".concat(credit.amount, " ").concat(credit.isApproved ? credit.unit : 'h');
  }

  return creditString;
};

var filterOutDuplicatesFromCredits = function filterOutDuplicatesFromCredits(credits) {
  // Remove duplicates by unit. Keep credit item with the biggest amount of credits and isApproved: true (if applicable) for unit
  return credits.reduce(function (acc, item) {
    var existingCreditIndex = acc.findIndex(function (accItem) {
      return accItem.unit === item.unit;
    });
    var existingCredit = acc[existingCreditIndex];

    if (!existingCredit) {
      acc.push(item);
      return acc;
    } else if (item.amount > existingCredit.amount || item.isApproved && !existingCredit.isApproved) {
      acc[existingCreditIndex] = item;
      return acc;
    } else {
      return acc;
    }
  }, []);
};

var filterCredits = function filterCredits(credits, locale) {
  var countryCode = getCountryCode(locale);

  if (countryCode !== 'ch') {
    return filterOutDuplicatesFromCredits(credits);
  }
  /* 
         for switzerland
         In case content has more than one credit with `credits.isApproved = false`
         we will filter them and will just show the first one in the generated string
         example 1: [{ amount 8, isApproved: false, unit: 'CME'}, { amount 16, isApproved: false, unit: 'SGK'}]
         return should be [{ amount: 8, isApproved: false, unit: 'CME' }]
         and generated string should be '8 h'
         example 2:
         [
             { amount 8, isApproved: false, unit: 'CME'}, 
             { amount 16, isApproved: true, unit: 'SGK'},
             { amount 32, isApproved: false, unit: 'SGAIM'}
         ]
         return should be [{ amount: 8, isApproved: false, unit: 'CME' }, { amount: 16, isApproved: true, unit: 'SGK' }]
         and generated string should be '8 h | 16 SGK'
     */


  var alreadyHadNotApprovedCredit = false;
  var processedCredits = credits.filter(function (credit) {
    if (!credit.isApproved) {
      if (alreadyHadNotApprovedCredit) {
        return false;
      }

      alreadyHadNotApprovedCredit = true;
    }

    return true;
  });
  return filterOutDuplicatesFromCredits(processedCredits);
};

var ContentCreditsSeparator = ' | ';

var generateCreditsString = function generateCreditsString(credits, locale, numberOfCredits) {
  if (!credits.length) {
    return '';
  }

  var result = filterCredits(credits, locale).reduce(function (arr, each, index) {
    if (numberOfCredits && index >= numberOfCredits) {
      return arr;
    }

    var creditString = generateCreditString(each, locale);

    if (!creditString) {
      return arr;
    }

    return [].concat(_toConsumableArray(arr), [creditString]);
  }, []).join(ContentCreditsSeparator);
  return result;
};

var getContentLocale = function getContentLocale(content) {
  var _content$locale, _content$parent;

  var locale = (_content$locale = content === null || content === void 0 ? void 0 : content.locale) !== null && _content$locale !== void 0 ? _content$locale : content === null || content === void 0 ? void 0 : (_content$parent = content.parent) === null || _content$parent === void 0 ? void 0 : _content$parent.locale;

  if (locale) {
    return locale;
  }

  return null;
};

var getParticipationOptionIconName = function getParticipationOptionIconName(content) {
  switch (content.participationOption) {
    case Types.ContentParticipationOption.Hybrid:
      return 'hybrid';

    case Types.ContentParticipationOption.OnSite:
      return 'onsite';

    case Types.ContentParticipationOption.LiveStream:
    default:
      return 'livestream';
  }
};

var getParticipationOptionIconURL = function getParticipationOptionIconURL(_ref3) {
  var participationOption = _ref3.participationOption;

  switch (participationOption) {
    case Types.ContentParticipationOption.Hybrid:
      return 'https://storage.googleapis.com/bk-public-prod/public/static/icon-event-hybrid.svg';

    case Types.ContentParticipationOption.OnSite:
      return 'https://storage.googleapis.com/bk-public-prod/public/static/icon-event-onsite.svg';

    case Types.ContentParticipationOption.LiveStream:
    default:
      return 'https://storage.googleapis.com/bk-public-prod/public/static/icon-event-livestream.svg';
  }
};

var getDescriptionFields = function getDescriptionFields(_ref4, locale) {
  var _formatters$formatTra, _formatters$formatTra2;

  var description = _ref4.description,
      productDescription = _ref4.productDescription,
      descriptionTitle = _ref4.descriptionTitle,
      productDescriptionTitle = _ref4.productDescriptionTitle;
  var descriptionText = (_formatters$formatTra = formatters.formatTranslation(description, {
    locale: locale
  })) !== null && _formatters$formatTra !== void 0 ? _formatters$formatTra : formatters.formatTranslation(productDescription, {
    locale: locale
  });
  var descriptionTitleText = (_formatters$formatTra2 = formatters.formatTranslation(descriptionTitle, {
    locale: locale
  })) !== null && _formatters$formatTra2 !== void 0 ? _formatters$formatTra2 : formatters.formatTranslation(productDescriptionTitle, {
    locale: locale
  });
  return {
    description: descriptionText,
    descriptionTitle: descriptionTitleText
  };
};

var getBenefits = function getBenefits(content) {
  var _content$benefits;

  var benefitFilter = function benefitFilter(benefit) {
    return benefit.usage && [Types.BenefitUsage.Both, Types.BenefitUsage.Content].includes(benefit.usage);
  };

  var benefits = content === null || content === void 0 ? void 0 : (_content$benefits = content.benefits) === null || _content$benefits === void 0 ? void 0 : _content$benefits.filter(benefitFilter);

  if (!benefits || !benefits.length) {
    var _content$product, _content$product$bene;

    benefits = content === null || content === void 0 ? void 0 : (_content$product = content.product) === null || _content$product === void 0 ? void 0 : (_content$product$bene = _content$product.benefits) === null || _content$product$bene === void 0 ? void 0 : _content$product$bene.filter(benefitFilter);
  }

  return benefits;
};

var getTargetGroups = function getTargetGroups(locale, content) {
  var _targetGroups;

  var targetGroups = content === null || content === void 0 ? void 0 : content.targetGroups;

  if (!targetGroups || !targetGroups.length) {
    var _content$product2;

    targetGroups = content === null || content === void 0 ? void 0 : (_content$product2 = content.product) === null || _content$product2 === void 0 ? void 0 : _content$product2.targetGroups;
  }

  return (_targetGroups = targetGroups) === null || _targetGroups === void 0 ? void 0 : _targetGroups.filter(function (targetGroup) {
    return formatters.formatTranslation(targetGroup.title, {
      locale: locale
    });
  });
};

var getSpeakers = function getSpeakers(programSchedules) {
  return uniqBy(programSchedules.filter(function (each) {
    return each.speakers;
  }).map(function (each) {
    return each.speakers;
  }).flat(), 'id');
};

var isOnSite = function isOnSite(participationOption) {
  return participationOption === Types.ContentParticipationOption.Hybrid || participationOption === Types.ContentParticipationOption.OnSite;
};

var isLivestream = function isLivestream(participationOption) {
  return participationOption === Types.ContentParticipationOption.Hybrid || participationOption === Types.ContentParticipationOption.LiveStream;
};

var getSymposiumDescription = function getSymposiumDescription(content, intl) {
  var _content$product3;

  var intlLocale = intl.locale,
      messages = intl.messages;
  var contentLocale = contentUtils.getContentLocale(content);
  var locale = contentLocale !== null && contentLocale !== void 0 ? contentLocale : intlLocale;
  var symposiumProductName = formatters.formatTranslation(content === null || content === void 0 ? void 0 : (_content$product3 = content.product) === null || _content$product3 === void 0 ? void 0 : _content$product3.name, {
    locale: locale
  });
  var symposiymBrand = formatters.formatEnum(content.brand, {
    messages: messages,
    options: ContentTransformers.brand
  });
  var symposiumCity = formatters.formatTranslation(content.city, {
    locale: locale
  });
  var symposiumDate = formatters.formatDate(content.startDateTime, DateFormat.monthAndYear);
  var isWebup = content.brand === Types.ProductBrand.Wuex || content.brand === Types.ProductBrand.Wuif;
  var symposiumDescription = 'contentTitle' in content && isWebup ? "".concat(symposiumProductName, " ").concat(symposiymBrand, " ").concat(content.contentTitle) : "".concat(symposiumProductName, " ").concat(symposiymBrand, " ").concat(symposiumCity, " ").concat(symposiumDate);
  return symposiumDescription;
};

var isOnDemandAvailable = function isOnDemandAvailable(_ref5, isMember) {
  var contentType = _ref5.contentType,
      willBeOnDemand = _ref5.willBeOnDemand,
      endDateTime = _ref5.endDateTime,
      videoUrls = _ref5.videoUrls,
      startDateTime = _ref5.startDateTime,
      externalId = _ref5.externalId;

  if (externalId && DrupalEventTransformers.ukOnDemandEvents[externalId]) {
    return true;
  }

  var now = Dayjs();
  var onDemandAvailableMonths = contentType === Types.ContentType.Course && !isMember ? 6 : contentType === Types.ContentType.Webinar || isMember ? 14 : null; // Available On Demand from the first day of the event (if willBeOnDemand=true) and:
  // - visible for 6 months if COURSE and user is not a member
  // - visible for 14 months if WEBINAR or user is a member

  var showOnDemandButton = willBeOnDemand ? !!onDemandAvailableMonths && now.diff(startDateTime, 'day') >= 0 && now.diff(endDateTime, 'month') <= onDemandAvailableMonths : false;
  var someVideoAvailable = !!(videoUrls !== null && videoUrls !== void 0 && videoUrls.some(function (url) {
    return !!url;
  }));
  return showOnDemandButton && someVideoAvailable;
};

var isVideoContent = function isVideoContent(content) {
  return content.contentType === Types.ContentType.Lecture;
};

var isDocumentContent = function isDocumentContent(content) {
  return content.contentType === Types.ContentType.Pdf;
};

export default {
  isLive: isLive,
  getParticipationOptionIconName: getParticipationOptionIconName,
  getParticipationOptionIconURL: getParticipationOptionIconURL,
  isInTheFuture: isInTheFuture,
  generateCreditString: generateCreditString,
  ContentCreditsSeparator: ContentCreditsSeparator,
  generateCreditsString: generateCreditsString,
  getContentLocale: getContentLocale,
  getDescriptionFields: getDescriptionFields,
  getBenefits: getBenefits,
  getTargetGroups: getTargetGroups,
  getSpeakers: getSpeakers,
  isOnSite: isOnSite,
  isLivestream: isLivestream,
  getSymposiumDescription: getSymposiumDescription,
  isOnDemandAvailable: isOnDemandAvailable,
  isVideoContent: isVideoContent,
  isDocumentContent: isDocumentContent
};