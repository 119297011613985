import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import makeStyles from '@mui/styles/makeStyles';
export var createDimensions = function createDimensions(theme) {
  return {
    small: {
      container: {
        width: 240,
        margin: theme.spacing(0, 0, 0, 1.5)
      },
      image: {
        width: 240
      }
    },
    large: {
      container: {
        width: 263,
        margin: theme.spacing(0, 0, 0, 1.5)
      },
      image: {
        width: 260
      }
    }
  };
};
var useStyles = makeStyles(function (theme) {
  var _containerWithLogo;

  var dimensions = createDimensions(theme);
  return {
    container: _defineProperty({
      padding: theme.spacing(0.5),
      margin: dimensions.small.container.margin,
      width: dimensions.small.container.width,
      cursor: 'pointer',
      '&:hover $title': {
        textDecoration: 'underline'
      }
    }, theme.breakpoints.up('xl'), {
      margin: dimensions.large.container.margin,
      width: dimensions.large.container.width
    }),
    containerWithLogo: (_containerWithLogo = {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      padding: theme.spacing(0.5),
      cursor: 'pointer',
      '&:hover $title': {
        textDecoration: 'underline'
      }
    }, _defineProperty(_containerWithLogo, theme.breakpoints.up('sm'), {
      display: 'block',
      width: dimensions.small.container.width,
      height: 300
    }), _defineProperty(_containerWithLogo, theme.breakpoints.up('xl'), {
      display: 'block',
      width: dimensions.large.container.width
    }), _containerWithLogo),
    containerWithoutProviderLogo: {
      height: 34,
      marginBottom: theme.spacing(1)
    },
    imageContainer: _defineProperty({
      position: 'relative',
      marginBottom: theme.spacing(1.5),
      width: '100%'
    }, theme.breakpoints.up('sm'), {
      width: 230
    }),
    image: _objectSpread({
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }, theme.border({
      radius: 6,
      color: 'transparent'
    })),
    title: {
      color: theme.palette.primary.main,
      wordBreak: 'break-word',
      fontSize: 16,
      lineHeight: '18.75px',
      marginBottom: theme.spacing(1)
    },
    sponsorLogo: {
      objectFit: 'contain'
    }
  };
}, {
  name: 'LectureItem'
});
export default useStyles;