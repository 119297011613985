import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function () {
  return {
    container: {
      display: 'flex'
    },
    timeContainer: {
      flex: 1,
      textAlign: 'center'
    },
    unit: {
      fontSize: 10,
      lineHeight: '12px'
    },
    time: {
      fontWeight: 600
    }
  };
});
export default useStyles;