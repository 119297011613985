import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (theme) {
  return {
    container: {
      position: 'relative'
    },
    fallbackContainer: {
      backgroundColor: "".concat(theme.palette.secondary.main, "33"),
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5)
    },
    duration: _objectSpread({
      backgroundColor: "".concat(theme.palette.common.black, "B2"),
      color: theme.palette.common.white,
      position: 'absolute',
      bottom: 6,
      right: theme.spacing(1),
      padding: '1px 3px',
      lineHeight: '14px',
      fontWeight: 'bold',
      fontSize: 12
    }, theme.border({
      radius: 3,
      color: theme.palette.body.light
    }))
  };
}, {
  name: 'LectureThumbnail'
});
export default useStyles;