import { Types, booleanFilter } from 'common';
import uniq from 'lodash/uniq';
/**
 * This helper is useful to sort the attachments by file name
 *
 * @param attachments
 * @returns a list of Attachments sorted by file name
 */

var sortAttachments = function sortAttachments(attachments) {
  return attachments.sort(function (attachmentA, attachmentB) {
    var _attachmentA$file$nam, _attachmentA$file, _attachmentA$file$nam2, _attachmentB$file;

    return (_attachmentA$file$nam = attachmentA === null || attachmentA === void 0 ? void 0 : (_attachmentA$file = attachmentA.file) === null || _attachmentA$file === void 0 ? void 0 : (_attachmentA$file$nam2 = _attachmentA$file.name) === null || _attachmentA$file$nam2 === void 0 ? void 0 : _attachmentA$file$nam2.localeCompare((attachmentB === null || attachmentB === void 0 ? void 0 : (_attachmentB$file = attachmentB.file) === null || _attachmentB$file === void 0 ? void 0 : _attachmentB$file.name) || '')) !== null && _attachmentA$file$nam !== void 0 ? _attachmentA$file$nam : 0;
  });
};
/**
 * This helper is useful to sort the attachments by the groupId
 *
 * @param attachments
 * @returns a list of Attachments sorted by groupId
 */


var sortAttachmentSections = function sortAttachmentSections(attachments) {
  return attachments.sort(function (attachmentA, attachmentB) {
    var _attachmentA$groupId$, _attachmentA$groupId;

    return (_attachmentA$groupId$ = attachmentA === null || attachmentA === void 0 ? void 0 : (_attachmentA$groupId = attachmentA.groupId) === null || _attachmentA$groupId === void 0 ? void 0 : _attachmentA$groupId.localeCompare((attachmentB === null || attachmentB === void 0 ? void 0 : attachmentB.groupId) || '')) !== null && _attachmentA$groupId$ !== void 0 ? _attachmentA$groupId$ : 0;
  });
};

var getAttachments = function getAttachments(_ref) {
  var content = _ref.content,
      isEventBooked = _ref.isEventBooked;

  if (!(content !== null && content !== void 0 && content.attachments)) {
    return [];
  } else if (isEventBooked) {
    return content.attachments;
  }

  return sortAttachments(content.attachments.filter(function (attachment) {
    return attachment.attachmentType === Types.AttachmentType.Public || attachment.attachmentType === Types.AttachmentType.Authenticated;
  }));
};

var categorizeAttachments = function categorizeAttachments(props) {
  var attachments = getAttachments(props);
  var attachmentsWithGroup = [];
  var attachmentsWithNoGroup = [];
  attachments.forEach(function (attachment) {
    if (attachment.groupId && attachment.groupId.length > 0) {
      attachmentsWithGroup.push(attachment);
    } else {
      attachmentsWithNoGroup.push(attachment);
    }
  });
  return {
    attachmentsWithGroup: sortAttachments(attachmentsWithGroup),
    attachmentsWithNoGroup: sortAttachments(attachmentsWithNoGroup)
  };
};

var getAttachmentGroupIds = function getAttachmentGroupIds(attachments) {
  return uniq(sortAttachmentSections(attachments).map(function (attachment) {
    return attachment.groupId;
  })).filter(booleanFilter);
};

var getAttachmentsByGroup = function getAttachmentsByGroup(groupId, attachments) {
  return sortAttachments(attachments.filter(function (attachment) {
    return attachment.groupId === groupId;
  }));
};

var getIconName = function getIconName(attachment) {
  return attachment.attachmentType === Types.AttachmentType.DigitalCoursebook ? 'book' : 'download';
};

export default {
  getAttachments: getAttachments,
  categorizeAttachments: categorizeAttachments,
  getAttachmentGroupIds: getAttachmentGroupIds,
  getAttachmentsByGroup: getAttachmentsByGroup,
  getIconName: getIconName
};