import _defineProperty from "/home/runner/work/fomf-learning-platform/fomf-learning-platform/frontend/node_modules/.pnpm/next@12.3.4_@babel+core@7.18.2_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { makeStyles } from '@mui/styles';
var LEFT_MENU_WIDTH = 164;

var LEFT_MENU_MARGIN = function LEFT_MENU_MARGIN(theme) {
  return theme.spacing(5);
};

var useStyles = makeStyles(function (theme) {
  return {
    content: _defineProperty({
      position: 'relative'
    }, theme.breakpoints.up('md'), {
      marginTop: theme.spacing(4)
    }),
    nav: _defineProperty({
      width: LEFT_MENU_WIDTH,
      display: 'none',
      marginRight: LEFT_MENU_MARGIN(theme)
    }, theme.breakpoints.up('md'), {
      display: 'block'
    }),
    navItem: {
      height: 44,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1.5),
      cursor: 'pointer'
    },
    selectedNavItem: {
      backgroundColor: theme.palette.secondary[100],
      borderRadius: 6,
      '& > p': {
        color: theme.palette.secondary.main
      }
    },
    navItemIcon: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(2)
    },
    navDivider: {
      margin: theme.spacing(1, 0)
    },
    sticky: {
      '&+$programContent': _defineProperty({}, theme.breakpoints.up('md'), {
        position: 'relative',
        top: 0,
        left: LEFT_MENU_WIDTH,
        marginLeft: LEFT_MENU_MARGIN(theme)
      })
    },
    programContent: _defineProperty({
      flex: 1
    }, theme.breakpoints.up('md'), {
      maxWidth: "calc(100% - ".concat(LEFT_MENU_WIDTH, "px - ").concat(LEFT_MENU_MARGIN(theme), "px)")
    }),
    descriptionTitle: {
      color: theme.palette.body.dark,
      marginTop: theme.spacing(2),
      lineHeight: '24px'
    }
  };
});
export default useStyles;