import { useState, useEffect } from 'react';
import Dayjs from 'dayjs';

var twoDecimalPoint = function twoDecimalPoint(n) {
  return n > 9 ? n : '0' + n;
};

var useTimer = function useTimer(dateString) {
  var hideRedundant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var date = Dayjs(dateString);
  var now = Dayjs();
  var diff = date.unix() - now.unix();

  var _useState = useState(Dayjs.duration(diff * 1000)),
      duration = _useState[0],
      setDuration = _useState[1];

  useEffect(function () {
    var intervalId = setInterval(function () {
      var now = Dayjs();
      var diff = date.unix() - now.unix();
      setDuration(Dayjs.duration(diff * 1000));
    }, 1000);

    if (diff <= 0) {
      clearInterval(intervalId);
    }

    return function () {
      return clearInterval(intervalId);
    };
  });

  if (diff <= 0) {
    var _hideYears = true;

    var _hideMonths = _hideYears && hideRedundant.includes('month');

    var _hideDays = _hideMonths && hideRedundant.includes('day');

    var _hideHours = _hideDays && hideRedundant.includes('hour');

    var _hideMinutes = _hideHours && hideRedundant.includes('minute');

    var _hideSeconds = _hideMinutes && hideRedundant.includes('second');

    return {
      years: '0',
      months: '0',
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      hideYears: _hideYears,
      hideMonths: _hideMonths,
      hideDays: _hideDays,
      hideHours: _hideHours,
      hideMinutes: _hideMinutes,
      hideSeconds: _hideSeconds
    };
  }

  var years = duration.get('year');
  var months = duration.get('month');
  var daysNumber = duration.get('day');
  var hoursNumber = duration.get('hour');
  var minutesNumber = duration.get('minute');
  var secondsNumber = duration.get('second');
  var days = twoDecimalPoint(daysNumber);
  var hours = twoDecimalPoint(hoursNumber);
  var minutes = twoDecimalPoint(minutesNumber);
  var seconds = twoDecimalPoint(secondsNumber);
  var hideYears = years < 1;
  var hideMonths = hideYears && hideRedundant.includes('month') && months < 1;
  var hideDays = hideMonths && hideRedundant.includes('day') && daysNumber < 1;
  var hideHours = hideDays && hideRedundant.includes('hour') && hoursNumber < 1;
  var hideMinutes = hideHours && hideRedundant.includes('minute') && minutesNumber < 1;
  var hideSeconds = hideMinutes && hideRedundant.includes('second') && secondsNumber < 1;
  return {
    years: years,
    months: months,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    hideYears: hideYears,
    hideMonths: hideMonths,
    hideDays: hideDays,
    hideHours: hideHours,
    hideMinutes: hideMinutes,
    hideSeconds: hideSeconds
  };
};

var unitLocaleIds = {
  year: {
    singular: 'common.timer.year.singular',
    plural: 'common.timer.year.plural'
  },
  month: {
    singular: 'common.timer.months.singular',
    plural: 'common.timer.months.plural'
  },
  day: {
    singular: 'common.timer.days.singular',
    plural: 'common.timer.days.plural'
  },
  hour: {
    singular: 'common.timer.hours.singular',
    plural: 'common.timer.hours.plural'
  },
  minute: {
    singular: 'common.timer.minutes.singular',
    plural: 'common.timer.minutes.plural'
  },
  second: {
    singular: 'common.timer.seconds.singular',
    plural: 'common.timer.seconds.plural'
  }
};
/*
 * this will return the plural or singular locale Id based on the value
 */

var getUnitLocaleId = function getUnitLocaleId(value, unit) {
  return Number(value) === 1 ? unitLocaleIds[unit].singular : unitLocaleIds[unit].plural;
};

export default {
  useTimer: useTimer,
  getUnitLocaleId: getUnitLocaleId
};